import React from 'react';
import { isAndroid, isIOS, isMacOs, isWindows } from 'react-device-detect';
import { useTranslation } from 'react-i18next';

import { ImageCore, Text } from '@components';
import { Box } from '@core';
import { APPS_LINKS } from '@shared/constants/apps';

import googlePlayWhite from '@images/google-play-badge-white.svg';
import appStoreWhite from '@images/app-store-apple-white.svg';
import googlePlayBlack from '@images/google-play-badge.svg';
import appStoreBlack from '@images/app-store-apple.svg';
import windowsWhite from '@images/windows-badge-white.svg';
import windowsBlack from '@images/windows-badge.svg';

const singleLinkObject = {
  iOS: {
    link: APPS_LINKS.universalLink,
    logo: {
      black: appStoreBlack,
      white: appStoreWhite,
      alt: 'App Store',
    },
  },
  macOS: {
    link: APPS_LINKS.mac,
    logo: {
      black: appStoreBlack,
      white: appStoreWhite,
      alt: 'App Store',
    },
  },
  android: {
    link: APPS_LINKS.universalLink,
    logo: {
      black: googlePlayBlack,
      white: googlePlayWhite,
      alt: 'Google Play',
    },
  },
  windows: {
    link: APPS_LINKS.windows,
    logo: {
      black: windowsBlack,
      white: windowsWhite,
      alt: 'Windows',
    },
  },
};

const multipleLinksArray = [
  {
    link: APPS_LINKS.windows,
    logo: { black: windowsBlack, white: windowsWhite, alt: 'Windows' },
  },
  {
    link: APPS_LINKS.ios,
    logo: { black: appStoreBlack, white: appStoreWhite, alt: 'App Store' },
  },
  {
    link: APPS_LINKS.android,
    logo: {
      black: googlePlayBlack,
      white: googlePlayWhite,
      alt: 'Google Play',
    },
  },
];

const getSingleLink = ({ isIOS, isMacOs, isAndroid, isWindows }) => {
  if (isIOS) {
    return singleLinkObject.iOS;
  }
  if (isMacOs) {
    return singleLinkObject.macOS;
  }
  if (isAndroid) {
    return singleLinkObject.android;
  }
  if (isWindows) {
    return singleLinkObject.windows;
  }
};

const DownloadLink = ({ link, logo, alt }) => (
  <a href={link} rel="noopener noreferrer">
    <ImageCore src={logo} alt={alt} height={44} mx={4} />
  </a>
);

const AppsLinks = ({ isOneButton, isWhite, wrapperProps }) => {
  const oneButtonLink = isOneButton && getSingleLink({ isIOS, isMacOs, isAndroid, isWindows });
  const { t } = useTranslation();

  return oneButtonLink ? (
    <Box {...wrapperProps}>
      <Text.Body4 textAlign="center" mb={8}>
        {t('common:actions.downloadAtlasVpn')}
      </Text.Body4>

      <DownloadLink
        link={oneButtonLink.link}
        logo={isWhite ? oneButtonLink.logo.white : oneButtonLink.logo.black}
        alt={oneButtonLink.logo.alt}
      />
    </Box>
  ) : (
    <Box {...wrapperProps}>
      {multipleLinksArray.map(({ link, logo }) => (
        <DownloadLink
          key={logo.alt}
          link={link}
          logo={isWhite ? logo.white : logo.black}
          alt={logo.alt}
        />
      ))}
    </Box>
  );
};

export default AppsLinks;
